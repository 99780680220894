import { RcdData, RequestDeviceParameters } from '../common/models/antart-api-request.model';
import { DataPoints, DeviceSetting, LegendData, Point, RangeData, RcdResponseInfo, UserSetting } from '../common/models/antar-api-response.model';
import { AntarConstants } from '../common/antar.constants';

export class RcdUtils {
	private static rcdDevice: { sensitivity: number, breakTime: number };
	private static rcdResponse: RcdResponseInfo[] = [];
	public static getRcdData ( data: RcdData[], rcdUserSettings: UserSetting[] ): RcdResponseInfo[]
	{
		this.rcdResponse = [];
		data.forEach( ( rcd: RcdData ) => {
			if ( rcd.breakerPoisitioning === AntarConstants.BREAKER_POSITIONING['incomer'] )
			{
				this.getRcdValues( rcdUserSettings, rcd );
			}
			if ( rcd.breakerPoisitioning === AntarConstants.BREAKER_POSITIONING['outgoing'] || rcd.breakerPoisitioning === '' )
			{
				this.getRcdValues( rcdUserSettings, rcd );
			}
		} );
		return this.rcdResponse;
	}
	public static getRcdSettings ( rcd: RcdData [] ): DeviceSetting []
	{
		const rcdDeviceSettings: DeviceSetting[] = [];
		let rcdLegendDataList: LegendData[] = [];
		rcd.forEach( ( data: RcdData ) => {
			rcdLegendDataList = [];
			data.rcdParameters.forEach( ( element: RequestDeviceParameters ) => {
				const rcdLegend = {} as LegendData;
				const rcdRangeData = {} as RangeData;
				if ( AntarConstants.POSITION.includes( data.breakerPoisitioning ?? '' ) || data.breakerPoisitioning === '' )
				{
					if ( element.paramName === AntarConstants.RCD_SETTINGS['iDn'] )
					{
						rcdRangeData.values = [ ...data.sensitivityList ].sort( ( a, b ) => a - b );
						rcdRangeData.defaultValue = parseFloat( element.paramValue );
					}
					if ( element.paramName === AntarConstants.RCD_SETTINGS['iDt'] )
					{
						rcdRangeData.values = [ ...data.tempoTimeList ].sort( ( a, b ) => a - b );
						rcdRangeData.defaultValue = parseFloat( element.paramValue );
					}
					rcdRangeData.isAdjustable = data.isAdjustable;
					rcdLegend.settingName = element.paramName;
					rcdLegend.rangeData = rcdRangeData;

					rcdLegendDataList.push( rcdLegend );
				}
			} );
			rcdDeviceSettings.push( {
				deviceId: data.deviceId ?? '',
				deviceType: 'RCD',
				rcdId: data.rcdId,
				deviceLegendName: data.deviceId === '' ? `${data.rcdDesignation} ${data.rating} ${data.sensitivity} ` : `${data.rcdDesignation}`,
				legendDataList: rcdLegendDataList
			} );
		} );
		return rcdDeviceSettings;
	}
	private static getResponseDataPoints ( rcdDevice: { sensitivity: number, breakTime: number } ): DataPoints[]
	{
		const data_points: DataPoints[] = [];
		const point = this.getRcdDataPoints( rcdDevice );
		const rcdPoints: DataPoints = {
			curveName: AntarConstants.CEL_CURVE_NAME['Min'],
			deviceType: AntarConstants.DEVICE_TYPE['RCD'],
			points: point
		};
		data_points.push( rcdPoints );

		return data_points;
	}
	public static getRcdDataPoints ( rcd: { sensitivity: number, breakTime: number } ): Point[]
	{
		const leftTop: Point = { x: 0, y: 0 };
		const leftBottom: Point = { x: 0, y: 0 };
		const rightBottom: Point = { x: 0, y: 0 };
		const rcd_Points: Point[] = [];
		leftTop.x = rcd.sensitivity;
		leftTop.y = AntarConstants.RCD_POINTS['LeftTop'];

		leftBottom.y = rcd.breakTime;
		leftBottom.x = rcd.sensitivity;

		rightBottom.x = AntarConstants.RCD_POINTS['RightBottom'];
		rightBottom.y = rcd.breakTime;

		rcd_Points.push( leftTop );
		rcd_Points.push( leftBottom );
		rcd_Points.push( rightBottom );
		return rcd_Points;
	}
	private static getRcdValues ( rcdUserSettings: UserSetting[], rcd: RcdData )
	{
		const rcdValues : { sensitivity: number, breakTime: number } = { sensitivity: 0, breakTime: 0 };
		rcdUserSettings.find( ( item: UserSetting ) => {
			if ( rcd.rcdId === item.deviceId && item.settingName === AntarConstants.RCD_SETTINGS['iDn'] )
			{
				rcdValues.sensitivity = parseFloat( item.value );
			}
		} );
		rcdUserSettings.find( ( item: UserSetting ) => {
			if ( rcd.rcdId === item.deviceId && item.settingName === AntarConstants.RCD_SETTINGS['iDt'] )
			{
				rcdValues.breakTime = parseFloat( item.value );
			}
		} );
		this.rcdDevice = {
			...this.rcdDevice,
			sensitivity: rcdValues.sensitivity,
			breakTime: rcdValues.breakTime
		};
		const rcd_point = this.getResponseDataPoints( this.rcdDevice );

		this.rcdResponse.push( {
			dataPoints: rcd_point,
			settingsData: [],
			deviceId: rcd.deviceId,
			rcdId: rcd.rcdId,
			breakerPositioning: rcd.breakerPoisitioning
		} );
	}
	public static processRcdResponse ( rcdUserSettingsData: UserSetting[], rcdDeviceInfo: RcdData[], rcdDeviceSettings: DeviceSetting[] ): RcdResponseInfo[] {
		let rcdUserSettings: UserSetting[] = [];
		rcdUserSettings = rcdUserSettingsData.filter( ( data: UserSetting ) => {
			if ( AntarConstants.RCD_SETTINGS_NAME.includes( data.settingName.toLowerCase() ) ) {
				return data;
			}
			return false;
		} );
		const rcdDeviceResponse = RcdUtils.getRcdData( rcdDeviceInfo, rcdUserSettings );
		rcdDeviceResponse.forEach( ( rcd: RcdResponseInfo ) => {
			rcdDeviceSettings.forEach( data => {
				if ( rcd.rcdId === data.rcdId )
					rcd.settingsData.push( data );
			} );
		} );
		return rcdDeviceResponse;
	}
	public static analyzeSelectivity (
		rcdDatapoints: number[][][],
		rcdBreakerPositionsList: Array<{ name: string; color: string; id: string; }>
	  ): string {
		const incomerIndex = this.findBreakerIndex( rcdBreakerPositionsList, AntarConstants.BREAKER_TYPES['INCOMER'] );
		const outgoingIndex = this.findBreakerIndex( rcdBreakerPositionsList, AntarConstants.BREAKER_TYPES['OUTGOING'] );
		if ( incomerIndex === -1 || outgoingIndex === -1 ) return '';
		const incomerPoints = rcdDatapoints[incomerIndex];
		const outgoingPoints = rcdDatapoints[outgoingIndex];
		if ( !incomerPoints?.length || !outgoingPoints?.length ) return '';
		if ( this.isTotalSelectivity( incomerPoints, outgoingPoints ) ) return AntarConstants.SELECTIVITY_TYPE['TOTAL'];
		if ( this.isNoSelectivity( incomerPoints, outgoingPoints ) ) return AntarConstants.SELECTIVITY_TYPE['NO_SELECTIVITY'];
		if ( this.hasMatchingPoints( incomerPoints, outgoingPoints ) ) return AntarConstants.SELECTIVITY_TYPE['PARTIAL'];
		const crossingPoints = this.findCurveIntersections( incomerPoints, outgoingPoints );
		if ( crossingPoints.length > 0 ) return AntarConstants.SELECTIVITY_TYPE['PARTIAL'];
		return '';
	  }

	  public static findBreakerIndex (
		rcdBreakerPositionsList: Array<{ name: string; color: string; id: string; }>,
		breakerType: string
	  ): number {
		return rcdBreakerPositionsList.findIndex( pos => pos.name === breakerType );
	  }

	  public static isTotalSelectivity ( incomerPoints: number[][], outgoingPoints: number[][] ): boolean {
		return incomerPoints[0][0] > outgoingPoints[0][0] &&
			   incomerPoints[1][1] > outgoingPoints[1][1] &&
			   incomerPoints[2][1] > outgoingPoints[2][1];
	  }

	  public static isNoSelectivity ( incomerPoints: number[][], outgoingPoints: number[][] ): boolean {
		return ( incomerPoints[0][0] < outgoingPoints[0][0] && incomerPoints[1][1] < outgoingPoints[1][1] &&
			   incomerPoints[2][1] < outgoingPoints[2][1] ) || ( incomerPoints[0][0] === outgoingPoints[0][0] &&
			   incomerPoints[1][1] === outgoingPoints[1][1] && incomerPoints[2][1] === outgoingPoints[2][1] );
	  }

	  public static hasMatchingPoints ( incomerPoints: number[][], outgoingPoints: number[][] ): boolean {
		for ( const incomerPoint of incomerPoints ) {
			for ( const outgoingPoint of outgoingPoints ) {
			  if ( incomerPoint[0] === outgoingPoint[0] && incomerPoint[1] !== outgoingPoint[1] ) {
				return true;
			  }
			  if ( incomerPoint[0] !== outgoingPoint[0] && incomerPoint[1] === outgoingPoint[1] ) {
				return true;
			  }
			}
		  }
		  return false;
	  }
	  public static findCurveIntersections ( curve1: number[][], curve2: number[][] ): number[][] {
		const intersections: number[][] = [];
		for ( let i = 0; i < curve1.length - 1; i++ ) {
			const line1 = {
				x1: curve1[i][0],
				y1: curve1[i][1],
				x2: curve1[i + 1][0],
				y2: curve1[i + 1][1]
			  };
		  for ( let j = 0; j < curve2.length - 1; j++ ) {
			const line2 = {
				x1: curve2[j][0],
				y1: curve2[j][1],
				x2: curve2[j + 1][0],
				y2: curve2[j + 1][1]
			  };
			const intersection = this.findLineIntersection( line1, line2 );
			if ( intersection ) {
			  intersections.push( [ intersection[0], intersection[1] ] );
			}
		  }
		}
		return intersections;
	  }

	  public static findLineIntersection ( line1: {x1: number, y1: number, x2: number, y2: number}, line2: {x1: number, y1: number, x2: number, y2: number} ): number[] | null {
		const { x1, y1, x2, y2 } = line1;
  		const { x1: x3, y1: y3, x2: x4, y2: y4 } = line2;
		const denominator = ( ( y4 - y3 ) * ( x2 - x1 ) - ( x4 - x3 ) * ( y2 - y1 ) );
		if ( denominator === 0 ) {
		  return null;
		}
		const ua = ( ( x4 - x3 ) * ( y1 - y3 ) - ( y4 - y3 ) * ( x1 - x3 ) ) / denominator;
		const ub = ( ( x2 - x1 ) * ( y1 - y3 ) - ( y2 - y1 ) * ( x1 - x3 ) ) / denominator;
		if ( ua < 0 || ua > 1 || ub < 0 || ub > 1 ) {
		  return null;
		}
		// Calculate intersection point
		const x = x1 + ua * ( x2 - x1 );
		const y = y1 + ua * ( y2 - y1 );
		return [ x, y ];
	  }
}
