import { createReducer, on } from '@ngrx/store';
import * as appActions from './app.actions';
import { User } from '../core/models/user.model';
import { CBDeviceData, RcdData, RequestData, RequestDeviceParameters, RequestNetworkParameters, TransformerDeviceData } from '../common/models/antart-api-request.model';
import { DeviceSetting, NetworkParameters, Selectivity, ResponseDeviceData, UserSetting, NetworkSetting, CableParameters, ResponseData, Notifications, RcdResponseInfo, Response, TicCurveResponse } from '../common/models/antar-api-response.model';
import { v4 as uuid } from 'uuid';
import { Loader } from '../common/models/antar-ui.model';
import { AntarConstants } from '../common/antar.constants';
import { RcdUtils } from '../core/rcd.utils';
import { TicUtils } from '../core/tic.utils';

export interface State {
	user: User | null;
	requestData: RequestData;
	deviceData: ResponseDeviceData[];
	deviceSetting: DeviceSetting[];
	networkParameters: NetworkParameters[];
	userSetting: {
		device: UserSetting[],
		common: NetworkSetting[]
	};
	selectivity?: Selectivity | null;
	rcdSelectivity:string;
	loader: {
		mainLoader: Loader,
		informationLoader: Loader
	};
	notification: Notifications[];
	rcdDeviceData: RcdData[];
	rcdDeviceResponse: RcdResponseInfo[];
	userSelection: { chartType: string };
	rcdSettings: DeviceSetting[];
	selectivityByTable: Response | null;
	ticCurveResponse: TicCurveResponse[];
	selectedGroup: { group: string };
}

export const initialState: State = {
	user: null,
	rcdSelectivity: '',
	requestData: {
		applicationName: '',
		data: [],
		networkParameters: [],
		rcdData: [],
		limits: {
			rcd: {
				xMin: 0,
				xMax: 0,
				yMin: 0,
				yMax: 0
			},
			tic: {
				xMin: 0,
				xMax: 0,
				yMin: 0,
				yMax: 0
			},
			i2t: {
				xMin: 0,
				xMax: 0,
				yMin: 0,
				yMax: 0
			}
		}
	},
	deviceData: [],
	deviceSetting: [],
	networkParameters: [],
	userSetting: { device: [], common: [] },
	selectivity: null,
	loader: { mainLoader: { load: false, message: '' }, informationLoader: { load: false, message: '' } },
	notification: [],
	rcdDeviceData: [],
	rcdDeviceResponse: [],
	userSelection: { chartType: 'It' },
	rcdSettings: [],
	selectivityByTable: null,
	ticCurveResponse: [],
	selectedGroup: { group: '' }
};

export const appReducer = createReducer(
	initialState,
	on( appActions.LoadUser, ( state: State, { user }: { user: User } ) => ( { ...state, user: user } ) ),
	on( appActions.LoadGroup, ( state: State, { group }: { group: string } ) => {
		return {
			...state,
			selectedGroup: { ...state.selectedGroup, group: group }
		};
	}
	),
	on( appActions.ResetStates, () => initialState ),
	on( appActions.UpdateInputRequestData, ( state: State, { deviceId, paramName, paramValue, checked }: { deviceId: string, paramName: string, paramValue: string, checked?: boolean } ) => {
		const updatedRequestData = state.requestData.data.map( ( data: CBDeviceData ) => {
			if ( data.deviceId !== '' && data.deviceId !== null && deviceId === data.deviceId ) {
				const updatedDeviceNetworkParameters = data.deviceNetworkParameters.map( ( deviceParam ) => {
					if ( paramName === deviceParam.paramName ) {
						return { ...deviceParam, paramValue: paramValue };
					}
					return deviceParam;
				} );
				return { ...data, deviceNetworkParameters: updatedDeviceNetworkParameters };
			}
			return data;
		} );
		const updatedUserSettingDevice = state.userSetting.device.map( ( deviceData: UserSetting ) => {
			if ( deviceData.deviceId !== '' && deviceData.deviceId !== null && deviceData.settingName === paramName && deviceData.deviceId === deviceId ) {
				return { ...deviceData, deviceId: deviceData.deviceId, settingName: paramName, checked: checked, value: paramValue, deviceName: deviceData.deviceName, groupId: deviceData.groupId };
			} else {
				return deviceData;
			}
		} );
		return {
			...state,
			requestData: {
				...state.requestData,
				data: updatedRequestData
			},
			userSetting: {
				...state.userSetting,
				device: updatedUserSettingDevice
			}
		};
	} ),
	on( appActions.LoadInputDeviceData, ( state: State, { inputDeviceData }: { inputDeviceData: RequestData } ) => {
		const userSettingsData: UserSetting[] = [];
		const commonSettings: NetworkSetting[] = [];
		const rcdDeviceData: RcdData[] = [];
		const groupRdcData: RcdData[] = [];
		let selectedGroupData: string = state.selectedGroup.group;
		const generatedGroupId = uuid();

		const updatedData = processDeviceData( inputDeviceData, userSettingsData, commonSettings, generatedGroupId );

		selectedGroupData = getSelectedGroupData( updatedData, selectedGroupData );

		processRcdData( inputDeviceData, rcdDeviceData, userSettingsData, selectedGroupData );

		filterGroupRdcData( rcdDeviceData, groupRdcData, state.selectedGroup.group );

		const ticCurveData: TransformerDeviceData[] = updatedData.filter( item => item.protectionDeviceType == 'Transformer' );
		const ticCurveResponse = TicUtils.generateTicCurvePoints( ticCurveData );

		return {
			...state,
			requestData: {
				...inputDeviceData,
				networkParameters: filterNetworkParameters( inputDeviceData.networkParameters ),
				data: updatedData,
				rcdData: rcdDeviceData
			},
			userSetting: {
				...state.userSetting,
				common: [ ...commonSettings ],
				device: [ ...userSettingsData ]
			},
			rcdDeviceData: rcdDeviceData,
			rcdSettings: RcdUtils.getRcdSettings( groupRdcData ),
			rcdDeviceResponse: RcdUtils.processRcdResponse( userSettingsData, groupRdcData, RcdUtils.getRcdSettings( rcdDeviceData ) ),
			ticCurveResponse: ticCurveResponse,
			selectedGroup: { group: selectedGroupData }
		};
	} ),
	on( appActions.LoadResponseDataSuccess, ( state: State, { responseData, launch }: { responseData: ResponseData, launch:string } ) => {
		const updatedData: CBDeviceData[] = state.requestData.data.map( requestd => ( { ...requestd } ) );
		const deviceData = extractDeviceData( responseData );
		const deviceSetting = extractDeviceSettings( responseData, updatedData );
		const groupRdcData = extractGroupRdcData( state );
		const userSettingsData = extractUserSettingsData( state );
		return {
			...state,
			deviceData,
			deviceSetting,
			networkParameters: responseData['tccDataResponse']['networkParameters'],
			selectivity: responseData.tccDataResponse.selectivity ?? null,
			requestData: { ...state.requestData, data: updatedData, combinedSelectivity: launch === AntarConstants.LAUNCHSYSTEM.ANTARES && responseData.tccDataResponse.selectivity ? responseData.tccDataResponse.selectivity : undefined, selectivityByCurve: launch === AntarConstants.LAUNCHSYSTEM.CEL && responseData.tccDataResponse.selectivity ? responseData.tccDataResponse.selectivity : undefined },
			rcdSettings: RcdUtils.getRcdSettings( groupRdcData ),
			rcdDeviceResponse: RcdUtils.processRcdResponse( userSettingsData, groupRdcData, RcdUtils.getRcdSettings( groupRdcData ) )
		};
	} ),
	on( appActions.LoadSelectivityResultDataSuccess, ( state: State, { selectivity, launch }: { selectivity: Selectivity, launch:string } ) => {
		return ( {
			...state,
			selectivity: selectivity,
			requestData: {
				...state.requestData,
				combinedSelectivity: launch === AntarConstants.LAUNCHSYSTEM.ANTARES ? selectivity : undefined,
				selectivityByCurve: launch === AntarConstants.LAUNCHSYSTEM.CEL ? selectivity : undefined
			}
		} );
	} ),
	on( appActions.UpdateLoader, ( state: State, { loadingtype, loader, message }: { loadingtype: string, loader: boolean, message: string } ) => {
		return {
			...state,
			loader: {
				mainLoader: loadingtype === AntarConstants.LOADER_TYPE['MAIN_LOADER'] ? { load: loader, message: message } : state.loader.mainLoader,
				informationLoader: loadingtype === AntarConstants.LOADER_TYPE['INFORMATION_LOADER'] ? { load: loader, message: message } : state.loader.informationLoader
			}
		};
	} ),
	on( appActions.SetErrorMessage, ( state: State, { notification }: { notification: Notifications[] } ) => {
		return ( {
			...state,
			notification: notification
		} );
	} ),
	on( appActions.UpdateUserSelection, ( state: State, { chartType }: { chartType: string } ) => {
		return {
			...state,
			userSelection: { ...state.userSelection, chartType }
		};
	} ),
	on( appActions.UpdateRcdDeviceRequest, ( state: State, { deviceId, paramName, paramValue }: { deviceId: string, paramName: string, paramValue: string } ) => {
		const updatedRcdDeviceData = state.rcdDeviceData.map( ( rcd: RcdData ) => {
			if ( deviceId === rcd.rcdId && rcd.groupId?.includes( state.selectedGroup.group ) ) {
				const updatedRcd = rcd.rcdParameters.map( ( rcdParam: RequestDeviceParameters ) => {
					if ( paramName === rcdParam.paramName.toLowerCase() ) {
						return { ...rcdParam, paramValue: paramValue };
					}
					return rcdParam;
				} );
				return { ...rcd, rcdParameters: updatedRcd };
			}
			return rcd;
		} );
		const updatedUserSettingDevice = state.userSetting.device.map( ( deviceData: UserSetting ) => {
			if ( deviceData.deviceId !== '' && deviceData.deviceId !== null && deviceData.settingName.toLowerCase() === paramName && deviceData.deviceId === deviceId ) {
				return { ...deviceData, deviceId: deviceData.deviceId, settingName: deviceData.settingName, value: paramValue, deviceName: deviceData.deviceName };
			} else {
				return deviceData;
			}
		} );
		const updatedDeviceSetting = RcdUtils.getRcdSettings( updatedRcdDeviceData );
		return {
			...state,
			rcdDeviceData: updatedRcdDeviceData,
			userSetting: {
				...state.userSetting,
				device: updatedUserSettingDevice
			},
			rcdSettings: updatedDeviceSetting,
			rcdDeviceResponse: RcdUtils.processRcdResponse( updatedUserSettingDevice, updatedRcdDeviceData, updatedDeviceSetting )
		};
	} ),
	on( appActions.LoadSelectivityResultDataByTableSuccess, ( state: State, { selectivity }: { selectivity: Response } ) => {
		return ( {
			...state,
			selectivityByTable: selectivity,
			requestData: {
				...state.requestData,
				selectivityByTable: selectivity.data
			}
		} );
	} ),
	on( appActions.setRCDSelectivityStatus, ( state: State, { rcdSelectivity } ) => {
		return {
			...state,
			rcdSelectivity: rcdSelectivity
		};
	} )
);

function extractDeviceData ( responseData: ResponseData ): ResponseDeviceData[] {
	return responseData['tccDataResponse']['curveDataPoints'].map( ( rawDeviceData: ResponseDeviceData ) => {
		return { ...rawDeviceData, settingsData: undefined };
	} );
}

function extractDeviceSettings ( responseData: ResponseData, updatedData: CBDeviceData[] ): DeviceSetting[] {
	return responseData['tccDataResponse']['curveDataPoints'].map( ( rawDeviceData: ResponseDeviceData ) => {
		const deviceSetting: DeviceSetting = {
			deviceId: rawDeviceData.deviceId,
			breakerPositioning: rawDeviceData.breakerPositioning,
			deviceType: getDeviceType( rawDeviceData.deviceType ),
			deviceLegendName: rawDeviceData['settingsData']?.deviceLegendName,
			legendDataList: rawDeviceData?.['settingsData']?.legendDataList
		};

		updateRequestData( updatedData, rawDeviceData );
		return deviceSetting;
	} );
}

function getDeviceType ( deviceType: string ): 'Circuit Breaker' | 'RCD' | 'Fuse' {
	if ( deviceType === AntarConstants.DEVICE_TYPE['CircuitBreaker'] ) {
		return 'Circuit Breaker';
	}
	if ( deviceType === AntarConstants.DEVICE_TYPE['Fuse'] ) {
		return 'Fuse';
	}
	return 'Circuit Breaker';
}

function updateRequestData ( updatedData: CBDeviceData[], rawDeviceData: ResponseDeviceData ): void {
	updatedData.forEach( requestd => {
		if ( rawDeviceData.deviceId === requestd.deviceId && requestd.celDeviceSettings ) {
			requestd.celDeviceSettings = rawDeviceData.celDeviceSettings;
		}
	} );
}

function extractGroupRdcData ( state: State ): RcdData[] {
	return state.rcdDeviceData.filter( rdcData =>
		rdcData.groupId?.includes( state.selectedGroup.group )
	);
}

function extractUserSettingsData ( state: State ): UserSetting[] {
	if ( !state.requestData.rcdData ) return [];

	return state.requestData.rcdData.flatMap( ( rcd: RcdData ) =>
		rcd.rcdParameters?.flatMap( ( parameters: RequestDeviceParameters ) =>
			rcd.groupId?.map( groupId => ( {
				deviceId: rcd.rcdId ?? '',
				settingName: parameters.paramName,
				value: parameters.paramValue,
				groupId: groupId
			} ) ) ?? []
		) ?? []
	);
}
function processDeviceData ( inputDeviceData: RequestData, userSettingsData: UserSetting[], commonSettings: NetworkSetting[], generatedGroupId: string ) {
	return inputDeviceData.data.map( ( data: CBDeviceData ) => {
		const deviceName = data.deviceName;
		const deviceId = data.deviceId !== '' && data.deviceId !== null ? data.deviceId : uuid();
		const newData = { ...data };
		if ( newData.groupId === undefined ) {
			newData.groupId = [ generatedGroupId ];
		}
		if ( newData.deviceNetworkParameters ) {
			newData.groupId.forEach( groupId => {
				newData.deviceNetworkParameters.forEach( ( parameter: NetworkParameters ) => {
					userSettingsData.push( {
						deviceId: deviceId,
						settingName: parameter.paramName,
						value: parameter.paramValue,
						checked: true,
						deviceName: deviceName,
						groupId: groupId
					} );
				} );
			} );
		}

		inputDeviceData.networkParameters.forEach( ( parameter: CableParameters ) => {
			if ( data.isFocusedForCableCalc !== AntarConstants.BOOLEAN_STRING['FALSE'] &&
				AntarConstants.CABLE_TYPE.find( ( cableType ) => cableType === parameter.paramName ) ) {
				commonSettings.push( {
					deviceId: deviceId,
					settingName: parameter.paramName,
					value: parameter.paramValue
				} );
			}
		} );

		if ( data.deviceId !== '' && data.deviceId !== null ) {
			return newData;
		} else {
			return { ...newData, deviceId: deviceId, rcdPositioning: data.breakerPositioning };
		}
	} );
}

function getSelectedGroupData ( updatedData: CBDeviceData[], selectedGroupData: string ) {
	for ( const data of updatedData ) {
		if ( data.groupId && data.groupId.length > 0 ) {
			return data.groupId[0];
		}
	}
	return selectedGroupData;
}

function processRcdData ( inputDeviceData: RequestData, rcdDeviceData: RcdData[], userSettingsData: UserSetting[], selectedGroupData: string ) {
	inputDeviceData.rcdData?.map( ( rcddata: RcdData ) => {
		const rcdId: string = uuid();
		const correspondingCbData = inputDeviceData.data.find( cbData => cbData.deviceId === rcddata.deviceId );
		const rcdPositioning = correspondingCbData?.breakerPositioning;
		const newRcdDeviceData = { ...rcddata };
		inputDeviceData.data.forEach( deviceData => {
			if ( deviceData.deviceId === newRcdDeviceData.deviceId ) {
				newRcdDeviceData.groupId = deviceData.groupId;
			}
		} );

		const updatedRcdDeviceData = {
			rcdId: rcddata.rcdId === '' ? rcdId : rcddata.rcdId,
			deviceId: rcddata.deviceId,
			groupId: newRcdDeviceData.groupId ?? [ selectedGroupData ],
			deviceName: rcddata.deviceName,
			manufacturerId: rcddata.manufacturerId,
			rcdDesignation: rcddata.rcdDesignation,
			sensitivity: rcddata.sensitivity,
			isAdjustable: rcddata.isAdjustable,
			sensitivityList: rcddata.sensitivityList,
			tempoTimeList: rcddata.tempoTimeList,
			rcdParameters: rcddata.rcdParameters,
			breakerPoisitioning: rcdPositioning ?? '',
			rating: rcddata.rating
		};
		rcdDeviceData.push( updatedRcdDeviceData );

		if ( updatedRcdDeviceData.rcdParameters ) {
			updatedRcdDeviceData.rcdParameters.forEach( ( parameters: RequestDeviceParameters ) => {
				updatedRcdDeviceData.groupId?.forEach( ( groupId ) => {
					if ( groupId === selectedGroupData ) {
						userSettingsData.push( {
							deviceId: rcdId,
							settingName: parameters.paramName,
							value: parameters.paramValue,
							groupId: groupId
						} );
					}
				} );
			} );
		}
	} );
}

function filterGroupRdcData ( rcdDeviceData: RcdData[], groupRdcData: RcdData[], selectedGroup: string ) {
	rcdDeviceData.forEach( rdcData => {
		rdcData.groupId?.forEach( group => {
			if ( group == selectedGroup ) {
				groupRdcData.push( rdcData );
			}
		} );
	} );
}

function filterNetworkParameters ( networkParameters: RequestNetworkParameters[] ) {
	return networkParameters
		?.filter( ( parameter: RequestNetworkParameters | undefined ) => parameter !== undefined )
		.filter( ( parameter: RequestNetworkParameters ) => AntarConstants.CABLE_TYPE.includes( parameter.paramName ) );
}
export function processRcdResponse ( rcdUserSettingsData: UserSetting[], rcdDeviceInfo: RcdData[], rcdDeviceSettings: DeviceSetting[], selectedGroup?: string ): RcdResponseInfo[] {
	let rcdUserSettings: UserSetting[] = [];
	const rcdDeviceData: RcdData[] = [];
	rcdUserSettings = rcdUserSettingsData.filter( ( data: UserSetting ) => {
		if ( AntarConstants.RCD_SETTINGS_NAME.includes( data.settingName.toLowerCase() ) ) {
			return data;
		}
		return false;
	} );
	rcdDeviceInfo.forEach( rcdDevice => {
		rcdDevice.groupId?.forEach( group => {
			if ( group === selectedGroup ) {
				rcdDeviceData.push( rcdDevice );
			}
		} );
	} );
	const rcdDeviceResponse = RcdUtils.getRcdData( rcdDeviceData, rcdUserSettings );
	rcdDeviceResponse.forEach( ( rcd: RcdResponseInfo ) => {
		rcdDeviceSettings.forEach( data => {
			if ( rcd.rcdId === data.rcdId )
				rcd.settingsData.push( data );
		} );
	} );
	return rcdDeviceResponse;
}
