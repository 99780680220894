import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable( {
    providedIn: 'root'
} )

export class AppConfigService {

private config: AppConfig = { apiUrl: '',
    multivendorApiUrl: '',
    userTokenApi: '',
    launchSystem: '',
    multivendorSelectivityByTableApiUrl: '',
    userDetailsApi: '' };
    constructor ( private http: HttpClient ) {}
    loadConfig (): Promise<void> {
      return lastValueFrom( this.http.get<AppConfig>( '/config/config.json' ) )
          .then( configData => {
              this.config = configData;
              console.log( 'Config loaded:', this.config );
          } )
          .catch( error => {
              console.error( 'Could not load config:', error );
              throw new Error( `Could not load config: ${error.message}` );
          } );
  }

    getConfig (): AppConfig {
        return this.config;
    }

    setConfig ( config: AppConfig ): void {
        this.config = config;
    }

}

export interface AppConfig {
    apiUrl:string;
    multivendorApiUrl:string;
    userTokenApi:string;
    launchSystem:string;
    multivendorSelectivityByTableApiUrl:string;
    userDetailsApi:string;
}
