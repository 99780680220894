import { Injectable } from '@angular/core';
import { AppConfigService } from './appConfigService';

@Injectable( {
	providedIn: 'root'
} )

export class TokenService {

	constructor ( private appConfigService:AppConfigService ) {}
	private authToken: string = '';
	private launchSystem = this.appConfigService.getConfig().launchSystem;

	public getAuthToken (): string {
		return this.authToken;
	}

	public setAuthToken ( authToken: string ): void {
		this.authToken = authToken;
	}

	public getLaunchSystem (): string {
		return this.launchSystem;
	}

}
